.temporary {
  position: absolute;
  max-width: 448px;
  border-radius: 4px;
  background-color: hsl(213, 9%, 19%);
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  h2 {
    padding: 15px 0 0px;
    color: #af956e !important;
    font-family: "Istok Web" !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
  }
  p {
    color: #fbf8ef;
    font-family: "Istok Web";
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    padding: 0 20px;
    text-align: center;
  }
  a {
    color: #af956e;
    font-family: "Istok Web";
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  a:hover {
    color: #af956e !important;
  }
}

.section-title {
  color: #2d3136;
  font-family: Cardo;
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 0 40px !important;
  text-align: center;
}
.section-headline {
  color: #af956e;
  font-family: "Istok Web";
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 0 0 0;
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .temporary {
    margin-top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
  }
  .section-headline{
    font-size: 16px;
    font-weight: 400;
  }
  .section-title{
    font-size: 30px;
    font-weight: 700;
  }
}
@media only screen and (mind-width: 400px) and (max-width: 481px) {
  .temporary {
    width: 100%;
  }
}
