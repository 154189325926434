.home-news {
  width: 70%;
  margin: 50px auto;
  max-width: 1300px;
  .news-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    a{
      text-decoration: none;
      color: #2d3136;
    }
    .news-segment {
      width: 390px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .pic-surr{
        width: 100%;
        min-height: 200px; 
        img {
          width: 100%;
          height: auto;
          margin: auto 0; 
          
        }
      }      
      h3{
        color: #2d3136;
        font-family: "Istok Web";
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        padding-top: 15px;
      }
      span{
        color: #2d3136;
        font-family: "Pier Sans";
        font-size: 20px;
        font-weight: 700;
        line-height: 66px;
        text-decoration: underline;
        text-align: center;
      }
      p{
        overflow: hidden;
      }
    }
  }
}
.newsblocks {
  padding-top: 130px;
  display: flex;
  .newsblock {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 25px;
    background-color: none;
    border: 2px solid black;
    padding: 25px;
    img {
      width: 100%;
      height: auto;
    }
    .news-picture-subtext {
      color: $hero_grey;
      font-family: "Segoe UI";
      font-size: 20px;
      font-weight: 400;
    }
    h3 {
      color: #615e5d;
      font-family: $cardo_serif;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      padding-top: 39px;
      padding-bottom: 27px;
    }
    .intro-text {
      color: #615e5d;
      font-style: bold;
      font-family: $josefine_sans;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
      padding-bottom: 32px;
    }
    .news-text {
      color: #615e5d;
      font-family: $josefine_sans;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
    }
    span {
      color: #615e5d;
      font-family: "Pier Sans";
      font-size: 20px;
      font-weight: 700;
      line-height: 66px;
      text-decoration: underline;
    }
  }
}
@media only screen and (max-width: 940px) {
  .newsblocks {
    margin-bottom: 40px;
    flex-direction: column;
    .newsblock {
      width: 92% !important;
      margin-left: 4% !important;
      .news-picture-subtext {
        font-size: 12px;
        font-weight: 400;
      }
      .news-headline {
        font-size: 28px;
        font-weight: 700;
      }
      .intro-text {
        font-size: 16px;
        font-weight: 700;
      }
      .news-text {
        font-size: 16px;
        font-weight: 400;
      }
      .read-more {
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        text-decoration: underline;
      }
    }
  }
  .home-news{
    width: 94%;
    margin-left: 3%;
    .news-segment{
      max-width: 350px;
    }
  }
}
@media only screen and (max-width: 1671px){
  .home-news{
    .news-inner{
      a:nth-child(3){
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 837px){
  .home-news{
    .news-inner{
      justify-content: space-evenly;
      a:nth-child(2){
        display: none;
      }
    }
  }
}