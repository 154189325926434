.nav{
  position: fixed;
  top:0;
  left:0;
  z-index: 3;
  display: flex;
  height:94px;
  width:100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #2d3136;
  align-items:center;

  .menu-style{
      display: none;
  }
  .logo{
      margin-left: 8%;
      width: 195px;
      height: auto;
      img{
        width: 100%;
        height: auto;
      }
    }
  .navigation{
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

        li{
          list-style: none;

        }

        a{
          color: #fbf8ef;
          font-size: 14px;
          font-weight: 700;
          font-family: $josefine_sans;
          text-transform: uppercase;
          padding: 20px;
          text-decoration: none;
        }
  }
  .nav-icons{
    align-items: center;
    position: absolute;
    top: 50%;
    right: 12%;
    transform: translate(-50%, -50%);
    .nav-icon{
      margin:12px;
    }
  }
  .shop{
    text-transform: uppercase;
    width: 129px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #534e4c;
    font-size: 16px;
    font-weight: 700;
    color: $shop;
    background-color: $beige;
    font-family: $josefine_sans;
    text-align: center;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(-50%, -50%);
  }
}
.navigation-mobile{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: -4;
    transition: scale 2000ms;
    background-image: url("../img/mobile_bg.jpg");
    background-size: cover;
    background-repeat: repeat-y;
    height: 100vh;
    //max-width: 95vw;
    .red-logo{
        width: 80%;
        height: auto;
        margin: 10%;
        display: none;
    }
    .layer{
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100vh;
    }

}
#mobile-display{
    transition: all 1s ease-in-out;

}

@media only screen and (min-width:1025px) and (max-width:1400px){

        .shop{
          right: -3% !important;
        }


}
@media only screen and (min-width:1025px){
    #mobile-display{
        transform: none !important;
        background-color: white !important;
    }
}
@media only screen and (max-width:940px){
    .nav{
        height: 52px;
        .logo{
            width: 140px;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
        }
        .menu-link{
            margin-left: 5%;
            .menu-style{
                cursor: pointer;
                display: inline-block;
            }
        }
        .navigation{
          display: none !important;
        }
        .nav-icons{
          right: 0;
        }
        .shop{
          display: none;
        }
    }
    .navigation-mobile{
        display: block;
        .layer{
          z-index:-1;
        }
        .red-logo{
          z-index: 1;
        }
        .nav-mobile{
            z-index: 2;
            list-style-type: none;
            height: 80vh;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            margin-top: 10vh;
            .nav-link{
                color: #ffffff;
                font-family: $josefine_sans;
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
            }

        }
    }
}
