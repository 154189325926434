.home {
  .hero {
    z-index: 2;
    position: relative;
    height:89vh;
    //max-height: 80vh;
    background-size: cover;
    background-position: center;   
    overflow: hidden;
    min-height: 670px;
    .hero-content {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $hero_grey;
      text-align: center;
      line-height: 0.5;
      h2 {
        color: #fbf8ef;
        font-family: "Istok Web";
        font-size: 30px;
        font-weight: 400;
        text-transform: uppercase;
      }
      h3 {
        color: #fbf8ef;
        font-family: Cardo;
        font-size: 62px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 25px 0;
      }
    }
  }
}
.about {
  .about-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 320px;
    justify-content: center;
    background-size: cover;
    h3 {
      text-align: center;
      color: #5d636a;
      font-family: "Istok Web";
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
    }
    h2 {
      text-align: center;
      color: #fbf8ef;
      font-family: Cardo;
      font-size: 40px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

@media only screen and (max-width: 940px) {
  .home {
    .hero {
      height: 90vh;
      max-height: 90vh;
      .hero-media {
        img {
          display: none;
        }
        background-image: url("../img/mobile_hero.jpg");
        background-size: cover;
        width: 100%;
        min-height: 678px;
      }
      .hero-content {
        width: 100%;
        top:39%;
        h2{
          font-size: 20px;
        }
        h3{
          font-size: 40px;
          padding: 5px 0 35px;
        }
      }
    }
  }
  .about {
    .header {
      flex-direction: column;
      align-items: center;
      .about-headline {
        font-size: 40px !important;
        width: 100%;
      }
      .about-top-text {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        .about-text {
          width: 100% !important;
          margin: 50px auto !important;
        }
      }
    }
  }
}
/*@media only screen and (min-width:320px) and (max-width: 480px){
  .home{
    .hero-content{
      position: absolute !important;
      top:21% !important;
      left:0 !important;
    }
  }
}
*/
