.kontakt-form {
  max-width: 794px;
  margin: 75px auto;
  .kontakt-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    input {
      width: 45%;
      margin: 8px 2.5%;
      border-radius: 4px 4px 0 0;
      background-color: #f2f2f2;
      border: none;
      border-bottom: 1px solid grey;
      box-sizing: border-box;
      color: #2d3136;
      font-family: "Istok Web";
      font-size: 16px;
      font-weight: 400;
      padding-left: 5px;
      text-transform: capitalize;
    }
    textarea {
      width: 95%;
      margin: 8px 2.5%;
      height: 110px;
      border-radius: 4px 4px 0 0;
      background-color: #f2f2f2;
      color: #2d3136;
      border: none;
      border-bottom: 1px solid gray;
    }
  }
  input {
    margin-top: 7px;
    margin-left: 2.5%;
    width: 312px;
    height: 40px;
    border-radius: 4px;
    background-color: #2d3136;
    color: #fbf8ef;
    font-family: "Istok Web";
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid black;
  }
  #adress {
    display: none;
  }
}
.newsletter {
  position: relative;
  width: 70%;
  margin: 150px auto;
  min-height: 300px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  background-size: cover;
  max-width: 1300px;
  h2 {
    color: #fbf8ef;
    font-family: Cardo;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 1.6px;
    line-height: 48px;
    text-transform: uppercase;
  }
  h3 {
    color: #fbf8ef;
    font-family: "Istok Web";
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
  }
  img{
    max-width: 200px;
  }
  form {
    height: 40px;
    .nl-input {
      border-radius: 4px 0 0;
      background-color: #fbf8ef;
      border: 1px solid #fbf8ef;
      box-sizing: border-box;
      color: #2d3136;
      font-family: "Istok Web";
      font-size: 16px;
      font-weight: 400;
      height: 35px;
      padding-left: 5px;
    }
    .nl-button {
      border-radius: 0 4px 0 0;
      background-color: #af956e;
      color: #fbf8ef;
      border: 1px solid #af956e;
      font-family: "Istok Web";
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      width: 99px;
      height: 35px;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .newsletter {
    width: 94%;
    margin-left: 3%;
    flex-direction: column;
    min-height: 380px;
    background-size: cover;
    background-repeat: no-repeat;
    .newsletter-content {
      width: 80%;
    }
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 16px;
    }
    form{
      width: 80%;
      .nl-button{
        width: 85px;
      }
    }
  }
  .kontakt-form {
    .kontakt-content {
      width: 95%;
      margin: auto;
      input {
        width: 100% !important;
      }
    }
    input{
      width: 90%;
      max-width: 450px;
      margin: 0 5%;
    }
  }
}
