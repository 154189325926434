//colors

$beige: #fbf8ef;
$black: #202020;
$hero_grey: #746f6d;
$grey: #615e5d;
$white: #fbfbfb;
$shop: #534e4c;

//fonts
$josefine_sans: "Josefin Sans", sans-serif;
$cardo_serif: "Cardo", serif;
$montserrat_sans: "Montserrat", sans-serif;
$open_sans: "Open Sans", sans-serif;
