.selections{
    max-height: 100px;
    width: 100%;
    display: flex;
    background: #fff;

    .selection-pictures{        
        display: flex !important;
        height: 86px;
        .pic-inner{
          max-height: 86px;
          padding: 4%;
          filter: grayscale(100%);
          max-width: 215px;
          margin: auto;
        }
    }
}

@media only screen and (max-width:940px){
  .selections{
    margin-top: 0px;
    max-height: unset;
    height: 160px;
    .selection-pictures{
      max-width: unset;
      width: auto;
      margin: 0 auto;
      .pic-inner{
        max-width: 250px;        
        max-height: 140px;
        margin-top: 20px;
      }
    }
  }
}
