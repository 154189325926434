#hero-button{
  margin:0;
  //width: 250px;
  height: 60px;
  border-radius: 2px;
  background-color: #2d3136;
  line-height: 2.6;
  color: #fbf8ef;
  font-family: "Istok Web";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid #2d3136;
  padding: 0 34px;
}

#service-button{
  color: #2d3136;
  font-family: "Istok Web";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  width: 251px;
  height: 60px;
  border-radius: 2px;
  border: 1px solid #2d3136;
  margin-top: 70px;
  background-color: transparent;
}

.panel_button{
    width: 193px;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #af956e;
    color: #fbf8ef;
    background-color: #2d3136;
    font-family: "Istok Web";
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 35px;
}

.news-button{
    width: 251px;
    height: 52px;
    border-radius: 6px;
    border: 1px solid #746f6d;
    color: #746f6d;
    font-family: $josefine_sans;
    background-color: none;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 5%;
    margin-bottom: 30px;
}
#news-b-left{
  display: none;
}
#more{
  height: 52px;
  border-radius: 6px;
  border: 1px solid #746f6d;
  color: #746f6d;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 2.8;
  text-transform: uppercase;
  margin: 0 auto;  
}
#more-link{
  margin: 70px auto 70px auto;
}
@media only screen and (max-width: 940px){
  #hero-button{
    width: 199px;
    height: 42px;
    border-radius: 2px;
    background-color: #2d3136;
    font-size: 10px;
    padding: 0 15px;
  }
  #service-button{
    width: 199px;
    height: 42px;
    font-size: 10px;
    padding: 0 30px;
  }
}
button{
  cursor: pointer;
}
