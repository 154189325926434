.footer {
  width: 100%;
  height: 168px;
  min-height: 168px;
  background-color: #2d3136;
  //background-color: $beige;
  display: flex;
  align-items: center;
  position: relative;
  .footer-rows {
    display: flex;
    align-items: center;
    align-items: initial;
    .footer-row {
      display: flex;
      flex-direction: column;
      margin-left: 20%;
      color: #fbf8ef;
      text-transform: uppercase;
      a {
        text-decoration: none;
        color: #fbf8ef;
        font-size: 12px;
        font-weight: 400;
        font-family: $open_sans;
      }
    }
  }
  #footer-info {
    color: #fbf8ef;
    font-family: $open_sans;
    font-size: 12px;
    font-weight: 400;
    min-width: 234px;
    #firmname {
      font-size: 20px;
      margin: 0;
    }
  }
  .footer-logo {
    width: 160px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
      height: auto;
    }
  }
  #footer-form {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(-5%, -50%);
    h2 {
      color: #fbf8ef;
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
    }
    form {
      input {
        max-width: 350px;
        border-radius: 4px 0 0;
        background-color: #fbf8ef;
        border: 1px solid #fbf8ef;
        box-sizing: border-box;
        color: #2d3136;
        font-family: "Istok Web";
        font-size: 16px;
        font-weight: 400;
        height: 35px;
        box-sizing: border-box;
        line-height: normal !important;
        vertical-align: middle;
        padding-left: 5px;
      }
      button {
        border-radius: 0 4px 0 0;
        background-color: #af956e;
        color: #fbf8ef;
        border: 1px solid #af956e;
        font-family: "Istok Web";
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        width: 99px;
        height: 35px;
        cursor: pointer;
        vertical-align: middle;
      }
    }
  }
}
.copyright {
  height: 40px;
  color: #af956e;
  font-family: $open_sans;
  background-color: #2d3136;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  box-sizing: border-box;
  border-top: 1px solid #c1bba2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .cr{
    margin-bottom: 0;
  }  
}
@media only screen and (max-width: 940px) {
  .footer {
    flex-direction: column;
    height: unset;
    .footer-rows {
      display: flex;
      flex-direction: column;
      .footer-row {
        width: 100%;
        text-align: center;
        margin: 25px auto;
      }
    }
    .footer-logo {
      margin: 15px auto;
      position: unset;
      top: unset;
      left: unset;
      transform: unset;
    }
    #footer-form {
      margin: 50px auto;
      position: unset;
      right: unset;
      top: unset;
      transform: unset;
      form {
        input {
          width: 60%;
        }
        button {
          width: 40%;
          img {
            width: 35%;
          }
        }
      }
    }
  }
  .copyright {
    font-size: 14px;
  }
}
.footer #footer-form form input::placeholder {
  color: #c1bba2;
  text-align: center;
}
