.home {
  .panels {
    width: 70%;
    margin: auto;
    height: auto;
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 1350px;
    .panel-left {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      border-radius: 4px 0 0 4px;
      background-color: #2d3136;
      h3 {
        color: #af956e;
        font-family: "Istok Web";
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        margin: 50px auto 10px;
      }
      h1 {
        color: #fbf8ef;
        padding: 0 0 29px;
        font-family: Cardo;
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;
      }
      .seperator {
        height: 1px;
        width: 127px;
        border-bottom: 2px solid #af956e;
      }
      .salutation {
        color: #af956e;
        font-family: "Istok Web";
        font-size: 18px;
        font-weight: 400;
        padding: 35px 0 16px;
      }
      p {
        color: #fbf8ef;
        font-family: "Istok Web";
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        padding: 5px 40px;
        text-align: center;
        max-width: 60%;
      }
    }
    .panel-right {
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.about{
  margin-top: 94px;  
  .about-intro{
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: #fbf8ef;
    width: 70%;
    margin: 60px auto 90px;
    padding: 55px;
    max-width: 1300px;
    h2{
      color: #af956e;
      font-family: "Istok Web";
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom:25px;
    }
    p{
      color: #2d3136;
      font-family: "Istok Web";
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      #dots{
        display: none;

      }
    }
  }
  .team{
    display:flex;
    flex-direction: column;
    width: 70%;
    margin: 50px auto;
    max-width: 1300px;
    .team-content{
      display: flex;
      flex-direction: column;
      width: 100%;
      .team-member{
        display: flex;
        width: 100%;
        margin-bottom: 80px;
        .member-pic{
          width: calc(100%/3);
          max-width: 425px;
          img{
            width:100%;
            height: auto;
          }
        }
        .member-text{
          width: 66%;
          padding:38px;
          h2{
            color: #af956e;
            font-family: "Istok Web";
            font-size: 30px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 30px;
          }
          p{
            color: #202020;
            font-family: "Istok Web";
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
          }
        }
      }
    }
  }
  .testimonials{
    min-height: 560px;
    width: 100%;
    padding:90px;
    .quotes{
      .slick-track{
        background-color: transparent !important;
      }
      .quote{
        display: flex !important;
        flex-direction: column;
        align-items: center;  
        min-height: 350px;     
        justify-content: space-evenly; 
        p{
          color: #fbf8ef;
          font-family: Cardo;
          font-size: 22px;
          font-weight: 400;
          text-align: center;
          width: 75%;
          max-width: 1300px;
        }
        .quote-line{
          height:1px;
          border-bottom: 2px solid #af956e;
          width:87px;
        }
        .quote-name{
          color: #fbf8ef;
          font-family: "Istok Web";
          font-size: 20px;
          font-weight: 400;
        }
        .quote-source{
          color: #746f6d;
          font-family: "Istok Web";
          font-size: 20px;
          font-style: italic;
        }
      }
    }
  }
  .services-panels-about{
    .service-panel{
      border-radius: 4px;
      background-color: #fbf9f4;
      min-height: 490px;
      justify-content: flex-start !important;
      padding: 45px 10px !important;      
      .service-text{
        width: 75%;
        text-align: center;
      }
      .services-line{
        margin-bottom: 25px;
      }
    }
  }
}
.legal {
  padding-top: 115px;
  width: 70%;
  margin: 0 auto;
  
}
.agb {
  margin: 0 auto;
  width: 70%;
  padding-top: 115px;
  .agb-section {
    border-bottom: 2px solid goldenrod;
    padding: 15px 0;    
    h2 {
      text-transform: uppercase;
      font-size: 21px;
    }
    h4 {
      font-size: 13px;
    }
  }
  #adresse {
    border-bottom: none;
    h5 {
      padding: 10px 0;
    }
  }
}
.soon {
  padding: 0 !important;
  min-height: 100vh;
  background-size: cover;
  background-image: url("../img/hero_bg.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
    max-width: 100%;
    min-height: 600px;
  }
  p {
    color: white;
    font-size: 25px;
    cursor: pointer;
    max-width: 800px;
    width: 90%;
  }
}
.kontakt {
  background-color: white;
  margin-top: 94px;
  .topside {
    width: 100%;
    .header {
      width: 100%;
      margin-top: 10%;
      margin-bottom: 5%;
      h3 {
        color: #2d3136;
        font-family: $cardo_serif;
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        margin: 0 auto;
      }
      h4 {
        color: #746f6d;
        font-family: "Istok Web";
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0.96px;
        text-align: center;
        line-height: 100px;
      }
    }
    .contact-panels {
      display: flex;
      width: 70%;
      margin: 0 auto;
      min-height: 200px;
      .contact-panel {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% / 3);
        .contact-subtitle {
          color: #af956e;
          font-family: "Istok Web";
          font-size: 18px;
          font-weight: 400;
          padding: 3px;
          margin-top: 21px;
        }
        .contact-text {
          color: #2d3136;
          font-family: "Istok Web";
          font-size: 24px;
          font-weight: 400;
          text-align: center;
        }
      }
    }
  }
  .contact-divide {
    height: 1px;
    width: 80%;
    margin: 0 auto;
    border-top: 2px solid#af956e;
  }
}
.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 100px auto;
  max-width: 1300px;
  h1 {
    color: #2d3136;
    font-family: Cardo;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 0 60px;
  }
  h3 {
    color: #af956e;
    font-family: "Istok Web";
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0 0 0 0;
  }
  .services-panels {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: space-around;
    width: 120%;
    .service-panel {
      width: 120px;
      display: flex !important;
      flex-direction: column;
      justify-content: space-evenly;
      align-content: center;
      align-items: center;
      text-align: center;
      cursor:pointer;
      .service-title {
        color: #af956e;
        font-family: Cardo;
        font-size: 20px;
        font-weight: 700;
      }
      .service-text{
        text-align: center;
        color: #2d3136;
        font-family: "Istok Web";
        font-size: 18px;
        font-weight: 400;
      }
      img {
        height: 65px;
        width: auto;
        padding: 0 0 20px 0;
      }
      .services-line {
        width: 87px;
        border-top: 2px solid #af956e;
        height: 1px;
      }
    }
  }
}
@media only screen and (max-width: 1094px) {
  .home {
    .panels {
      flex-direction: column;
      width: 94%;
      margin-left: 3%;
      border-radius: 0;
      margin-top: 40px;
      .panel-left {
        width: 100%;

        p{
          max-width: unset;
        }
      }
      .panel-right {
        width: 100%;
      }
    }    
  }
  .about{
    margin-top: 52px;
    .about-intro{
      width:90%;
      padding: 55px 20px;
      #dots{
        display: block !important;
        cursor: pointer;
        color: #af956e;
        font-family: "Istok Web";
        font-size: 20px;
        font-weight: 400;
      }
      #more-text{
        display: none;
      }
    }
    .services{
      width: 100%;
      .services-panels{
        flex-direction: column;
        max-height: 480px;
        .service-text{
          width: 95%;
          font-size: 16px;
        }
      }
    }
    .services-panels-about{
      .slick-dots{
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .team{
      width: 90%;
      .team-content{
        .team-member{
          display: flex !important;
          flex-direction: column;
          align-items: center;
          .member-pic{
            width: unset;
          }
          .member-text{
            width:100%;

          }
        }
      }
    }
    .testimonials{
      background-size: cover;
      background-repeat: no-repeat;
      .quotes{
        .quote{
          p{
            width: 100%;
            font-size: 16px;
          }
        }
      }
    }
  }
  .kontakt {
    margin-top: 52px !important;
    .topside {
      .header {
        margin-top: 0 !important;
        h3 {
          text-align: center;
          margin: 0 auto !important;
        }
        h4 {
          line-height: 30px !important;
        }
      }
      .contact-panels {
        flex-direction: column;
        .contact-panel {
          width: 100% !important;
          margin: 25px 0 !important;    
          .contact-text{
            font-size: 21px;
          }      
        }
      }
    }
  }
  .agb {
    .agb-section {
      h2 {
        font-size: 20px;
      }
      .agb-text {
        margin-left: 0;
      }
    }
    #adresse {
      margin-left: 0;
    }
  }
  .legal {
    .agb-text {
      margin-left: 0;
    }
  }
  .services {
    h1 {
      font-size: 30px;
      text-align: center;
      padding-bottom: 30px;
    }
    .services-panels {
      .service-panel {
        width: 100%;
      }
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) {  
    .about{
      .slick-dots{
        li{
          button{
            height: 15px;
          }
        }        
      }
    }
  }
  @-moz-document url-prefix() {
    .about{
      .slick-dots{
        li{
          button{
            height: 18px;
          }
        }        
      }
    }
  }    
}

@media only screen and (min-width: 1025px) and (max-width: 1500px) {
  .inner-text {
    width: 100% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 520px) {
  .text-border {
    right: -32px;
  }
  .about{
    .team{
      .team-content{
        max-height: 420px;
        .team-member{
          .member-pic{
            width: 100%;            
          }
          .member-text{
            width: 100%;
            h2{
              font-size: 21px;
              text-align: center;
            }
            p{
              display: none;
            }
          }
        }
      }
    }
    .testimonials{
      padding: 50px 0;
    }
  }
}
.active {
  background-color: #ffffff !important;
}
.active-about {
  background-color: #534e4c !important;
}

@media only screen and (min-width:1920px){
  .panels{
    flex-direction: row !important;
    .panel-left{
      width:40%;
      border-radius: 4px 4px 0 0;
      p{
        max-width: unset !important;
      }
    }
    .panel-right{
      width: 60%;
    } 
  }
}