.recommended {
    width: 100%;
    min-height: 714px;
    background-repeat: no-repeat;
    margin: 100px 0;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
        color: white;
    }
    h3{
        margin-top: 50px;
    }
    .product-boxes{
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    height: 600px;   
    width: 100%;
    position: absolute;
    max-width: 1300px;
    margin: 0 auto;
    top: 50px;
    .product-box{
        width: 300px;
        a{
            text-decoration: none;
        }
        .newly-arrived-box{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            height: 470px;
            .img-fluid{
                max-height: 300px;
            }
        }
        .model{
            color: #fbf8ef;
            font-family: "Istok Web";
            font-size: 17px;
            font-weight: 700;
        }
        .manufacturer{
            color: #fbf8ef;
            font-family: "Istok Web";
            font-size: 17px;
            font-weight: 700;
        }
        .price{
            font-family: "Istok Web";
            font-size: 19px;
            font-weight: 700;
            color: #af956e;
        }
    }
  }
  .slick-arrow{
    color: white;
    transform: translateY(-542%);
  }
}