html{
  background-color: white;
}
.component{
  width: 100%;
}

.container{
  width:100%;
  margin:0;
  padding:0;
}
.about{
  background-color: #fff;
}
.contact{
  background-color: #fff;
}
.news{
  background-color: #fff;
}
.home{
  background-color: #fff;
  .panels{
    h3{
      opacity: 0.4;
      color: $hero_grey;
      font-family: $cardo_serif;
      font-size: 40px;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 70px;
    }
  }
}
